<template>
    <div id="app">

        <router-view />

    </div>
</template>

<script>
export default {
    mounted() {
        var designWidth = 375; // ui界面宽度
        var remPX = 16; // 在屏幕宽度为375px的时候，根元素字体大小为4px
        var scale = window.innerWidth / designWidth; // 当前屏幕与375屏幕的比例
        // 动态设置根元素字体大小
        document.documentElement.style.fontSize = scale * remPX + "px";
    },
    watch: {
        $route:{
            handler(val) {
                if(window.document) {
                    window.document.title = val.meta.title;
                }
            },
            immediate: true,
        },
    }
};
</script>

<style lang="scss">
/* 下划线 */
.ui-1px-b {
    position: relative;
}

.ui-1px-b::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background-color: #e5e5e5;
    /* 压缩宽度 */
    transform: scaleY(0.5);
}
</style>
