import axios from 'axios';
import vue from 'vue';
import golabalconfig from "@/allConfig/index.js";
//唯一实例

let env = process.env.NODE_ENV == "development";


const BASE_URL = golabalconfig.requestUrl;  // 上线地址

const DEBUG_BASE_URL = '/api';

class App {
    constructor() {
        var ajax = axios.create({
            baseURL: env ? DEBUG_BASE_URL : BASE_URL,
            timeout: 5000,
        });

        vue.prototype.$axios = ajax;
        console.log('全局方法库:初始化完成..');
    }
}

export default App;


